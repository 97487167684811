import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
  When starting a digital transformation program, you need to have a complete
  inventory of existing applications/solutions/platforms. We start by capturing
  them all in the Legacy Application Inventory. Do not worry if some are not
  being replaced. The applications that remain will also be in Target
  Application Inventory but we want them in both lists to ensure we have not
  missed any applications.
    </PageDescription>
    <p>{`Many times, a company will have an enterprise Configuration Management Database (CMDB). It is a database that
stores information about the components of an information system, such as hardware, software, network devices,
applications, and their relationships. The purpose of a CMDB is to provide a single source of truth for all
configuration items (CIs) in an organization’s IT environment, enabling efficient management of changes and
reducing risks associated with the changes. It is a fundamental part of IT Service Management (ITSM) and ITIL
(Information Technology Infrastructure Library) processes.`}</p>
    <p>{`The fields captured in a CMDB may vary depending on the organization’s specific needs and the tools used to
manage the database. However, some common fields that are typically included in a CMDB are:`}</p>
    <ol>
      <li parentName="ol">{`Application or Configuration Item (CI) or name`}</li>
      <li parentName="ol">{`Vendor of the CI`}</li>
      <li parentName="ol">{`Description of the CI`}</li>
      <li parentName="ol">{`Unique identifier or asset tag`}</li>
      <li parentName="ol">{`IT Owner or responsible party`}</li>
      <li parentName="ol">{`Business Owner or responsible party`}</li>
      <li parentName="ol">{`Location or physical address`}</li>
      <li parentName="ol">{`Relationships to other CIs`}</li>
      <li parentName="ol">{`Current status of the CI`}</li>
      <li parentName="ol">{`Version or release information`}</li>
      <li parentName="ol">{`Technical specifications or attributes`}</li>
      <li parentName="ol">{`Change history and audit trail`}</li>
      <li parentName="ol">{`Service level agreement (SLA) information`}</li>
      <li parentName="ol">{`Cost and financial information`}</li>
      <li parentName="ol">{`License information`}</li>
    </ol>
    <p>{`Other fields that may be included in a CMDB could be maintenance schedules, warranty information, aliases, and
performance metrics. The specific fields included in a CMDB depend on the organization’s requirements for
configuration management and the ITIL processes they are implementing.`}</p>
    <p>{`If there`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      